<template>
	<h1>Endorsing Content</h1>

	<div class="text-block bump-b-30">
		<h2>1. Hierarchy</h2>
		<p>Each entity has an <span class="code">endorse</span> field, of which the value can be:</p>
		<ul class="bump-b">
			<li>
				<span class="code">null</span> or <span class="code">undefined</span>: Yet to be reviewed (default)
			</li>
			<li><span class="code">0</span>: Not approved</li>
			<li><span class="code">1</span>: Approved</li>
			<li><span class="code">2</span>: Endorsed</li>
		</ul>
		<h3>Level 0: Unapproved / Not Yet Reviewed Content</h3>
		<p>
			This content will be ignored everywhere except search.
		</p>
		<h3>Level 1: Approved Content</h3>
		<p>
			This content can be shown anywhere, usually when it's relevant to other content you're already watching:
		</p>
		<ul class="bump-b">
			<li>Similar artists, artworks, users, rooms</li>
			<li>Rooms this artwork is in</li>
			<li>Collected by</li>
			<li>Label Search</li>
			<li>Color Search</li>
			<li>Etc.</li>
		</ul>
		<h3>Level 2: Endorsed Content</h3>
		<p>
			This is the content we push on an editorial level:
		</p>
		<ul class="bump-b">
			<li>Featured artists, users, rooms</li>
			<li>Staff Picks</li>
			<li>People you should follow</li>
			<li>Etc.</li>
		</ul>
	</div>

	<div class="text-block bump-b-30">
		<h2>2. Mechanics</h2>
		<p>
			All entities have the same <span class="code">endorse</span> field with consistent values, but the
			mechanics of endorsing are a bit more complex.
		</p>
		<h3>Artists</h3>
		<ul>
			<li>
				When you change the endorse level of an artist, this value is inherited by all their artworks. Artist
				and artwork are permanently linked.
			</li>
		</ul>
		<h3>Artworks</h3>
		<ul>
			<li>
				You can't change the individual endorse level of an artwork, because this is inherited from their
				artist parent.
			</li>
			<li>
				Instead, the endorse button on an artwork will toggle a separate Boolean
				<span class="code">promoted</span> field. A promoted artwork is a staff pick.
			</li>
			<li>
				This means there is a distinction between an artwork with
				<span class="code">endorse: 2</span>, which is an artwork from an endorsed artist, and an artwork with
				<span class="code">promoted: true</span> which is staff pick that was endorsed on its own merit. Not
				every staff pick comes from an endorsed artist, so an artwork can be promoted and have an endorse
				level of 0 or 1.
			</li>
		</ul>
		<h3>Users &amp; rooms</h3>
		<ul>
			<li>
				When a user or room is approved, the
				<span class="code">endorse</span> field is set to <span class="code">1</span>.
			</li>
			<li>
				When a user or room is endorsed, the
				<span class="code">endorse</span> field is set to <span class="code">2</span> AND all artists in the
				room or user collection have their <span class="code">endorse</span> field set to
				<span class="code">1</span> - unless they already had a higher endorse level. Attention: cannot be
				undone.
			</li>
			<li>
				Currently, this trickle-down approval only happens for previously collected artists, in the future
				endorsed users and rooms will continue to set the endorse level of the artists they collect.
			</li>
		</ul>
	</div>
</template>

<script>
import { injectMetaData } from '@/use/MetaData'

export default {
	name: 'DocMechanicsEndorsing',
	setup() {
		injectMetaData({
			title: 'Doc: Endorsing Mechanic',
		})
	},
	data() {
		return {
			html: ['<html>', '	<goes>', '		Here', '	</goes>', '</html>'],
		}
	},
	methods: {},
}
</script>

<style scoped lang="scss">
h3 {
	margin-top: 0.3rem;
}
</style>
